ul.uk-accordion {
  > li {
    border-bottom: 1px solid $gray1;

    &:last-child {
      border-bottom: none;
    }

    .uk-accordion-title {
      padding-bottom: 33px;
      transition: all 0.3s;
    }
    &.uk-open {
      border-color: transparent;
      &:after {
        @extend .d-block;
        @extend .w-100;
        @extend .position-absolute;
        @extend .bottom-0;
        height: 5px;
        content: "";
        background: $blue-green-gradiant;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      .uk-accordion-title {
        padding-bottom: 0;
      }
    }

    ul {
      list-style: none;
      li {
        @extend .d-flex;
        @extend .align-items-center;
        line-height: initial;
        margin-top: auto;
        margin-bottom: auto;
        &:before {
          content: "";
          width: 5px;
          height: 5px;
          border-radius: 100%;
          @extend .bg-gray2;
          @extend .d-block;
          @extend .me-12;
        }
      }
    }
  }
}