.email-form {
  label {
    @extend .font-roboto;
    @extend .fw-normal;
    @extend .fs-16;
    @extend .lh-24;
    @extend .text-blue4;
    @extend .mb-12;
  }

  textarea,
  input {
    @extend .py-10;
    @extend .px-12;
    @extend .text-gray2;
    border: 1px solid $gray3;
    line-height: initial;
    border-radius: 8px;
  }
}