.uk-accordion-title {
  @extend .position-relative;

  &:before {
    @extend .position-absolute;
    @extend .top-0;
    @extend .bottom-0;
    @extend .m-auto;
    right: 0;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACSSURBVHgBdY6xDQMhDEXxFdAywo2QbBBGyAbpacIkVECZbMIKN0JGuJYG8i0FKSGcJcuyeQ+bYoxZCKFLKcY5t4tJhBAeRHQBc17Qr8iTUip77/UBfOv9gsagvmZSh1trO/LKFxA/pJRWDPJn28bnSSl9hzEz1tqNWeq/fUsMAdYj/CPMpBH+E7qEcq+1PkeY4w3mJFiN/MZTjAAAAABJRU5ErkJggg==");
  }
}

.uk-open > .uk-accordion-title::before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABwSURBVHgBnVDLCcAgDDV2gY7Us1O0Z4fQDiEe7RTeO5lNQIOE9OKDIO8HJsYoSCmdNJpnpZBzDgBQaLAUpL/JcGstDo6lwzlnaq0va1oY36sXSufRe39zQYbRfMYusgRC5PB8gNm3SPa/MIG08cUlfPlfToRR+hcTAAAAAElFTkSuQmCC");
}

.uk-offcanvas-overlay {
  &.uk-open {
    @include media-breakpoint-down(md) {
      .uk-offcanvas-bar {
        width: 100%;
      }
    }
  }
}

.uk-offcanvas-close {
  right: 15px;
}