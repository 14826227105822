html {
  font-size: .625rem; // 1rem = 10px with this line (easier calcul)
  //font-size: .625rem calc(-350px / $ecran * $basevw);
}

header {
  background: $blue-green-gradiant;
}

p {
  @extend .font-roboto;
  @extend .fw-normal;
  @extend .fs-16;
  @extend .lh-24;
  @extend .text-blue2;
  @extend .mb-24;
  text-align: justify;
}

.arrow-header {
  @extend .bg-blue1;
  margin-top: -14rem;
  width: 70px;
  height: 70px;
  padding: 17.5px 30px;
  border-radius: 5px 30px 5px 5px;
}

#nous-contacter {
  #img-map {
    @include media-breakpoint-up(md) {
      width: 564px;
    }
  }
}