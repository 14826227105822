.card-horizontal {
  img {
    width: 517px;
    &.gradiant-card {
      @extend .position-absolute;
      @extend .bottom-0;
      @extend .h-100;
      width: 517px;
      top: 17px;
      left: 17px;
      right: 0;
      @include media-breakpoint-down(lg) {
        margin: auto;
        top: 34px;
        left: 34px;
      }

      @include media-breakpoint-down(md) {
        left: 17px;
      }
    }
  }

  &-reverse {
    img {
      &.gradiant-card {
        width: 517px;
        right: 17px;
        margin-left: auto;
        @include media-breakpoint-down(lg) {
          margin: auto;
          top: 34px;
          right: 34px;
          left: 0px;
        }

        @include media-breakpoint-down(md) {
          left: -17px;
        }
      }
    }
  }
}

#actualites {
  @include media-breakpoint-down(lg) {
    padding: 0 15%;
  }

  > div {
    @include media-breakpoint-down(lg) {
      display: flex;
      flex-wrap: wrap;
      .card-chiffre {
        flex: 50%;
        &:first-child,
        &:nth-child(2) {
          margin-bottom: 52px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      .card-chiffre {
        flex: 100%;
      }
    }
  }
}

.card-chiffre {
  width: 157px;
  height: 146px;
  @extend .me-lg-52;
  &:last-child {
    @extend .me-0;
  }
}

#equipe {
  > div {
    .profile {
      width: 312px;
      height: 386px;
      object-fit: contain;
    }

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-wrap: wrap;
      .card-profil {
        flex: 50%;
        &:first-child,
        &:nth-child(2) {
          margin-bottom: 24px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      .card-profil {
        flex: 100%;
      }
    }
  }
}

.card-profil {
  @extend .me-lg-24;
  &:last-child {
    @extend .me-0;
  }
  @include media-breakpoint-down(lg) {
    padding: 0 15px;
  }

  @include media-breakpoint-down(lg) {
    img.profile {
      border-radius: 8px;
      width: 100%;
    }
  }

  &:hover {
    .description {
      top: 16px;
    }
  }

  .description {
    top: 386px;
    bottom: 0;
    left: 18px;
    right: 18px;
    transition: all 0.8s cubic-bezier(.25,.75,.5,1.25);
    @include media-breakpoint-down(lg) {
      margin-top: 535px;
    }

    &.active {
      margin-top: 10px;
    }

    p {
      text-align: initial;
    }
  }

  .uk-button {
    @extend .bottom-0;
    @extend .start-0;
    @extend .end-0;
    @extend .m-auto;
    width: 88%;
  }
}